import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environment/environment';
import * as SHA1 from 'crypto-js/sha1';
import * as HmacSHA1 from 'crypto-js/hmac-sha1';
import * as encBase64 from 'crypto-js/enc-base64';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl = environment.baseUrl;
  router: any;
  // http://localhost/latest-techno/yvw_tracNet_mobile/src/app/getData.php
  private phpLoginUrl = "https://skysparkapi.infoasaservice.net.au/login.php"
  private phpGetDataUrl = "https://skysparkapi.infoasaservice.net.au/getData.php"
  
  constructor(private http: HttpClient) {
    
   }


   skySparkAuthentication(): Observable<any> {
    return this.http.post(this.phpLoginUrl, { 'username' : 'lw' , 'password' : 'demo8A' });
  }

  

  async sendRequest(query: string, cookie: string): Promise<any> {
    try {
      const response = await this.http.post(this.phpGetDataUrl, { 'query': query, 'cookie': cookie }).toPromise();      
     console.log(response, 'response'); 
     return response;
    } catch (error: any) { // Specify 'any' as the type or use a more specific type like HttpErrorResponse
      console.error('An error occurred');
      throw error; // Re-throw the error after handling it
    }
  }

  postRequest(endpoint: string, data:any) : Observable<any> {    
    return this.http.post(this.baseUrl + endpoint , data)
  }

  putRequest(endpoint: string, data: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  
    return this.http.put(this.baseUrl + endpoint, data, { headers: headers })
      .pipe(
        catchError(error => {
          console.error('Error in putRequest:', error);
          throw error;
        })
      );
  }
  

  getRequest(endpoint: string) : Observable<any> {
    return this.http.get(this.baseUrl + endpoint)
  }
  
  deleteRequest(endpoint: string) : Observable<any> {
    return this.http.delete(this.baseUrl + endpoint)
  }
   
}
