import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { environment } from '../../../environment/environment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Observable, Subject, map, startWith } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

interface ApiResponse {
  siteName: string;
  sensor: string;
  onOff: boolean;
  // Add more properties as needed
}

@Component({
  selector: 'app-new-manage-sensor',
  templateUrl: './new-manage-sensor.component.html',
  styleUrls: ['./new-manage-sensor.component.scss']
})
export class ManageSensorComponent implements OnInit {
  public cameraPermissionGranted = false;
  readonly imageTrigger: Subject<void> = new Subject<void>();
  error?: string;

  private filteredOptionsSubscription: Subscription | undefined;
  buttonTimestamp: any;
  formattedTimestamp: any;
  siteName: any;
  siteData: any[] = [];
  treeNodeData: any[] = [];
  sensorSiteData: any[] = [];
  google: any;
  result: any;
  issues: any;
  issueId: any
  notes: any;
  siteID: any;
  postedByName: any
  myFiles: string[] = [];
  data: any[] = [];
  siteSensorOnOffdata: any[] = [];
  responseArray: any[] = [];
  webcamId!: string;
  imageSource: string = '';
  isCaptured: any;
  mySiteName = new FormControl();
  mySiteNames: any
  filteredOptions: Observable<any[]> = new Observable<any[]>();
  sysImage: any;
  cookie: string = '';
  rows: any;
  onCount = 0;
  offCount = 0;
  selectedSiteName: string = "Canowindra Reservoir"; // Set a default value
  changesCount = 0;

  constructor(private api: ApiService, private toastr: ToastrService, private router: Router, private builder: FormBuilder) {
    this.mySiteName.setValue(this.selectedSiteName);
  }

  ngOnInit() {
    //this.setSiteNameBasedOnCoordinates();
    this.getAllTreeNode();
    this.onButtonPress();
    this.getDefaultSensorDataBySiteId();
    this.postedByName = sessionStorage.getItem('username');

    this.filteredOptions = this.mySiteName.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    // return this.siteData.filter(option => option.sitename.toLowerCase().includes(filterValue));
    return this.treeNodeData.filter(option => option.textLabel.toLowerCase().includes(filterValue));

  }

  setSiteNameBasedOnCoordinates() {
    this.api.getRequest('site-name').subscribe(item => {
      this.siteData = item;
    });

    this.afterChangeSensor();
  }

  getAllTreeNode() {

    this.api.getRequest(`sensors/treeNode/${environment.portalID}`).subscribe(item => {
      this.treeNodeData = item.data;

    });

    this.afterChangeSensor();
  }

  getDefaultSensorDataBySiteId() {
    this.api.getRequest('sensors/66a1fbd8db2ee14451ed90ff').subscribe(item => {
      this.sensorSiteData = item.data;
      this.onCount = 0;
      this.offCount = 0;
      this.sensorSiteData.forEach(sensor => {
        if (sensor.activeStatus === "ON") {
          this.onCount++;
        } else if (sensor.activeStatus === "OFF") {
          this.offCount++;
        }
      });
    });
  }

  afterChangeSensor() {

    if (this.treeNodeData.length == 0) return;
    const deafultSiteName: any = this.treeNodeData[0].textLabel;
    if (typeof deafultSiteName === 'undefined' || deafultSiteName == null) return
    this.mySiteName.setValue(deafultSiteName);

  }

  originalSensorSiteData: any[] = [];
  originalOnCount: number = 0;
  originalOffCount: number = 0;

  getSensorDataBySiteId(seiteId: string) {
    this.api.getRequest('sensors/' + seiteId).subscribe(item => {
      this.sensorSiteData = item.data;
      this.originalSensorSiteData = JSON.parse(JSON.stringify(item.data)); // Deep copy to store original data

      this.onCount = 0;
      this.offCount = 0;

      this.sensorSiteData.forEach(sensor => {
        if (sensor.activeStatus === "ON") {
          this.onCount++;
        } else if (sensor.activeStatus === "OFF") {
          this.offCount++;
        }
      });

      this.originalOnCount = this.onCount;
      this.originalOffCount = this.offCount;
    });
  }

  toggleSensorStatus(sensor: any) {
    if (sensor.activeStatus === "ON") {
      sensor.activeStatus = "OFF";
      this.onCount--;
      this.offCount++;
    } else if (sensor.activeStatus === "OFF") {
      sensor.activeStatus = "ON";
      this.onCount++;
      this.offCount--;
    }
  }


  countChanges() {
    this.changesCount = 0;
    this.sensorSiteData.forEach(option => {
      if (option.optionChanged) {
        this.changesCount++;
      }
    });
  }

  // onStatusChange(option: any, event: Event) {
  //   console.log(option, 'option');

  //   const selectedValue = (event.target as HTMLSelectElement).value;
  //   if (selectedValue == 'OFF') {
  //     option.offCondition = true;
  //     option.onCondition = false;
  //   } else {
  //     option.onCondition = true;
  //     option.offCondition = false;
  //   }
  //   if (selectedValue !== option.activeStatus) {
  //     option.activeStatus = selectedValue;
  //     option.optionChanged = true;
  //     this.countChanges();
  //   } else {
  //     option.optionChanged = false;
  //   }
  //   if (selectedValue) {
  //     this.siteSensorOnOffdata.push({
  //       sensor: option._id,
  //       onOff: selectedValue,
  //       siteName: option.treeNode,
  //       portal: environment.portalID
  //     });
  //   }
  // }

  onStatusChange(option: any, event: Event) {
    console.log(option, 'option');

    const selectedValue = (event.target as HTMLSelectElement).value;

    // Initialize originalActiveStatus if not already set
    if (option.originalActiveStatus === undefined) {
      option.originalActiveStatus = option.activeStatus;
    }

    // Update the condition flags based on selected value
    if (selectedValue === 'OFF') {
      option.offCondition = true;
      option.onCondition = false;
    } else {
      option.onCondition = true;
      option.offCondition = false;
    }

    // Check if the selected value is different from the current activeStatus
    if (selectedValue !== option.activeStatus) {
      option.activeStatus = selectedValue;

      // Check if selectedValue differs from the original status
      if (selectedValue !== option.originalActiveStatus) {
        option.optionChanged = true;
      } else {
        option.optionChanged = false;
      }

      // Update countChanges only for real changes
      this.countChanges();
    }

    // Only push to siteSensorOnOffdata if the option has really changed
    if (option.optionChanged) {
      const existingEntryIndex = this.siteSensorOnOffdata.findIndex(
        data => data.sensor === option._id
      );

      // Update the existing entry if found
      if (existingEntryIndex !== -1) {
        this.siteSensorOnOffdata[existingEntryIndex].onOff = selectedValue;
      }
      // Push new entry if it doesn't exist
      else {
        this.siteSensorOnOffdata.push({
          sensor: option._id,
          onOff: selectedValue,
          siteName: option.treeNode,
          portal: environment.portalID
        });
      }
    } else {
      // Remove entry from siteSensorOnOffdata if user reverts changes
      const existingEntryIndex = this.siteSensorOnOffdata.findIndex(
        data => data.sensor === option._id
      );

      if (existingEntryIndex !== -1) {
        this.siteSensorOnOffdata.splice(existingEntryIndex, 1);
      }
    }
  }


  optionSelected(event: MatAutocompleteSelectedEvent): void {
    if (this.filteredOptionsSubscription) {
      this.filteredOptionsSubscription.unsubscribe();
    }
    this.filteredOptionsSubscription = this.filteredOptions.pipe(take(1))
      .subscribe((options: any[]) => {
        const selectedOption = options.find(option => option.textLabel === event.option.viewValue);
        if (selectedOption) {
          const selectedId = selectedOption._id;
          this.selectedSiteName = event.option.viewValue;
          this.mySiteName.setValue(this.selectedSiteName);
          this.getSensorDataBySiteId(selectedId);
        }
      });
  }

  onButtonPress() {
    const datetimeWithZone = new Date().toLocaleString('en-GB', { timeZone: 'Australia/Brisbane', year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
    this.buttonTimestamp = datetimeWithZone;
  }


  async proceedData(): Promise<void> {

    try {
      const result = await Swal.fire({
        title: 'Confirmation',
        text: 'Do you want to proceed?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      });

      if (!result.isConfirmed) {
        return;
      }
      // const responses: ApiResponse[] = [];
      // await Promise.all(this.siteSensorOnOffdata.map(async (site) => {
      //   try {
      //     const response = await this.setSiteNameOnOff(site.siteName, site.siteSerialId, site.sensor, site.onOff);
      //     responses.push(response);
      //   } catch (error) {
      //     console.error(`Error for site ${site.siteName}:`, error);
      //     throw error;
      //   }
      // }));

      // this.siteSensorOnOffdata.forEach((site) => {
      //   const matchedResponse = responses.find((response) => response.siteName === site.equipRef_navName && response.sensor === site.sensor);
      //   if (matchedResponse) {
      //     Object.assign(site, matchedResponse);
      //   }
      // });

      const uniqueData = this.removeDuplicates(this.siteSensorOnOffdata, ['siteName', 'sensor']);

      const manageSensorResults = await Promise.all(uniqueData.map(async (response) => {
        try {
          return await this.manageSensor(response.siteName, response.sensor, response.onOff);
        } catch (error) {
          throw error;
        }
      }));

      if (manageSensorResults.length) {

        const siteResults = this.groupBySite(manageSensorResults);

        siteResults.forEach((site: any) => {

          const offCount = site.sensors.filter((sensor: any) => sensor.activeStatus === "OFF").length;
          const onCount = site.sensors.filter((sensor: any) => sensor.activeStatus === "ON").length;

          Swal.fire({
            icon: 'success',
            title: `Status Updated Successfully`,
            html: `<div style="width: 460px; max-width: 100%; background-color: #ffffff; border: 2px solid #4CAF50; border-radius: 8px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); text-align: center; padding: 20px; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;">
                    <h4 style="font-size: 24px; font-weight: bold; color: #4CAF50; margin-bottom: 10px;"></h4>
                    <div style="margin-bottom: 10px;">
                        <p style="font-size: 16px; font-weight: bold; margin: 0;">Sensors Updated</p>
                        <p style="font-size: 14px; margin: 0;">${site.sensors.length} Sensor${site.sensors.length !== 1 ? 's' : ''}</p>
                    </div>
                    <div style="display: flex; justify-content: ${onCount > 0 && offCount > 0 ? 'space-between' : 'center'}; margin-bottom: 10px;">
                        ${onCount > 0 ? `<div style="flex: 1; text-align: ${offCount > 0 ? 'left' : 'center'}; margin-right: ${offCount > 0 ? '10px' : '0'};"><p style="font-size: 14px; margin: 0; color: #4CAF50;">${onCount} Sensor${onCount !== 1 ? 's' : ''} ON</p></div>` : ''}
                        ${offCount > 0 ? `<div style="flex: 1; text-align: ${onCount > 0 ? 'right' : 'center'}; margin-left: ${onCount > 0 ? '10px' : '0'};"><p style="font-size: 14px; margin: 0; color: #f44336;">${offCount} Sensor${offCount !== 1 ? 's' : ''} OFF</p></div>` : ''}
                    </div>
                </div>`,
            showCloseButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didClose: () => {
              this.siteSensorOnOffdata = [];
              this.getSensorDataBySiteId(uniqueData[0].siteName);
              //this.getDefaultSensorDataBySiteId();
              //this.setSiteNameBasedOnCoordinates();
            }
          });
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No sensors were updated.',
          timer: 3500,
          timerProgressBar: true
        });
      }

    } catch (error) {
      Swal.fire('Error!', 'An error occurred during operation.', 'error');
    }
  }

  async setSiteNameOnOff(sitename: any, siteSerialId: any, sensor: any, onOff: any): Promise<any> {
    try {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().slice(0, 19).replace("T", " ");
      const query = `import_ON_OFF_01("${sitename}", "${siteSerialId}", "${sensor}", "${formattedDate}", "Brisbane", false, "${onOff}")`;
      const cookie = sessionStorage.getItem("access_token_skyspark");

      if (cookie != null) {
        const data = await this.api.sendRequest(query, cookie);
        return data;
      } else {
        throw new Error("Access token not valid");
      }
    } catch (error) {
      console.error(`Error in setSiteNameOnOff for site ${sitename}:`, error);
      throw error;
    }
  }

  async manageSensor(siteName: any, sensor: any, onOff: any): Promise<any> {
    try {
      const payload = {
        "portal": environment.portalID,
        "sensor": sensor,
        "treeNode": siteName,
        "activeStatus": onOff
      };
      const result = await this.api.postRequest(`sensors/update-status`, payload).toPromise();
      return result;
    } catch (error) {
      throw error;
    }
  }

  groupBySite(manageSensorResults: any[]): any[] {
    const siteMap = new Map();
    manageSensorResults.forEach(result => {
      if (!siteMap.has(result.siteName)) {
        siteMap.set(result.siteName, { siteName: result.siteName, sensors: [] });
      }
      siteMap.get(result.siteName).sensors.push(result);
    });
    return Array.from(siteMap.values());
  }

  removeDuplicates(array: any[], keys: string[]): any[] {
    const seen = new Set();
    return array.filter((item) => {
      const key = keys.map(k => item[k]).join('|');
      return seen.has(key) ? false : seen.add(key);
    });
  }
  cancel() {
    // Reset the sensor data to the original state
    this.sensorSiteData = JSON.parse(JSON.stringify(this.originalSensorSiteData));

    // Reset the counts to the original ON and OFF counts
    this.changesCount = 0;
    this.siteSensorOnOffdata = [];
  }

  transientPressureDataImportDateWise() {
    this.getDefaultSensorDataBySiteId();
    this.changesCount = 0;
  }


}
