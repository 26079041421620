import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  showElement!: boolean;
  constructor(private router: Router) {

    // if (sessionStorage.getItem('usertype') != 'user')
    //   this.showElement = true;
    // else
    this.showElement = true;
  }

  logout() {
    sessionStorage.clear();
    this.router.navigate([""]);
  }
}
