<style>
    body {
      background-color: #4472c4;
    }
  </style>
  
  <div class="login-main-bg-color">
    <div class="main-container">
      <div class="inner-row">
        <div class="login_screen">
          <img src="../../../assets/images/logo.png" alt="">
          <h2>User Authentication</h2>
          <form class="example-form" [formGroup]="verifyform" (ngSubmit)="proceedVerify()">
            <mat-form-field class="example-full-width">
              <mat-label>Enter the 6-digit code on mail id</mat-label>
              <input matInput formControlName="code" required>
            </mat-form-field>
            <button type="submit" class="save_btn"> Verify</button>
          </form>
        </div>
  
      </div>
    </div>
  </div>