<style>
    body {
      background-color: #4472c4;
    }
  </style>
  
  <div class="login-main-bg-color">
    <div class="main-container">
      <div class="inner-row">
        <div class="login_screen">
          <img src="../../../assets/images/logo.png" alt="">
          <h2>User SignUp</h2>
          <form class="example-form" [formGroup]="registrationForm" (ngSubmit)="onSubmit()">

            <mat-form-field class="example-full-width">
              <mat-label>Full Name</mat-label>
              <input matInput formControlName="name">
            </mat-form-field>

            <mat-form-field class="example-full-width">
              <mat-label>Username</mat-label>
              <input matInput formControlName="username">
            </mat-form-field>

            <mat-form-field class="example-full-width">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email">              
            </mat-form-field>

            <mat-form-field class="example-full-width">
              <mat-label>Password</mat-label>
              <input matInput type="password" formControlName="password">
            </mat-form-field>
  
            <div>
              <button class="lg-btn">Submit</button>
              <a routerLink="/" color="accent">Login?</a>
            </div>
            
          </form>
        </div>
  
      </div>
    </div>
  </div>