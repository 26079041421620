<style>
    body {
      background-color: #4472c4;
    }
  </style>
  
  <div class="login-main-bg-color">
    <div class="main-container">
      <div class="inner-row">
        <div class="login_screen">
          <img src="../../../assets/images/logo.png" alt="">
          <h2>Enter your email address and we will send you a link to reset your password.</h2>
          <form class="example-form" [formGroup]="form" (ngSubmit)="proceedlink()">
            <mat-form-field class="example-full-width">
              <mat-label>Enter the user mail id</mat-label>
              <input matInput formControlName="email" required>
            </mat-form-field>
            <button type="submit" class="save_btn"> Send password reset email</button>
            <a routerLink="/login" class="save_btn"> Back</a>
          </form>
        </div>
  
      </div>
    </div>
  </div>