import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent {
  constructor(private builder: FormBuilder, private router: Router, private api: ApiService, private toastr: ToastrService, ) { sessionStorage.clear(); }
  result: any;

  form = this.builder.group({
    email: this.builder.control('', Validators.required)
  });

  proceedlink() {
    
    let body: any= {
      email: this.form.value.email
    }
    if (this.form.valid) {
      this.api.postRequest('auth/login', body).subscribe(item => {
        this.result = item;        
        if (this.result) {
          this.toastr.warning('Please enter valid data.')
        }
      },error=> {        
        this.toastr.warning('Please enter valid data.')
      });
    }
  }
}
