<section class="header">
    <div class="main-container">
        <div class="inner-row">
            <div class="inner-flx-bx">
                <div class="logo">
                    <a routerLink="/home"><img src="../../../assets/images/logo.png" alt=""></a>
                </div>
                <a *ngIf="showElement" routerLink="/userlist">All User</a>
                <div class="log_out">
                    <img src="../../../assets/images/logout.png" alt=""  (click) = 'logout()'>
                </div>
            </div>
        </div>
    </div>
</section>