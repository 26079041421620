<app-header></app-header>
<style>
    .sensor-table {
        /* height: 450px; */
        overflow-y: scroll;
        margin-bottom: 30px;
    }

    table.sensors-tbl {
        max-width: 100%;
        width: 100%;
        border-collapse: collapse;
    }

    table.sensors-tbl,
    th,
    td {
        border: 1px solid rgb(222, 226, 230);
    }

    th,
    td {
        font-size: 14px;
        line-height: 24px;
        padding: 10px;
        text-align: center;
    }

    th {
        font-size: 22px;
        line-height: 34px;
        background-color: #4472c4;
        color: #fff;
    }

    .sensors-tbl tr th:nth-child(1) {
        width: 50%;
        text-align: left;
        padding-left: 20px;
    }

    .sensors-tbl tr td:nth-child(1) {
        width: 50%;
        text-align: left;
        padding-left: 20px;
    }

    .sensors-tbl tr:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.05);
    }

    .sensors-tbl td.status select {
        width: 60%;
        padding: 10px 18px;
        border: none;
        background-color: inherit;
        font-size: 14px;
        line-height: 24px;
    }

    .sensors-tbl td.status select:focus {
        outline: none;
    }

    body button.save_btn,
    body .btn_block {
        margin-top: 10px;
        margin-bottom: unset;
    }


    .cancel_btn {
        margin: 0px auto;
        display: block;
        text-align: center;
        margin-top: 30px;
        background-color: #4472c4;
        padding: 6px 10px;
        border: 1px solid #2f528f;
        color: #fff;
        border-radius: 5px;
        padding: 8px 50px 9px;
        font-size: 18px;
        margin-bottom: 20px;
        text-decoration: none;
    }


    .offClass {
        background-color: red !important;
    }

    .onClass {
        background-color: green !important;
    }

    select.form-control:focus {
        box-shadow: none !important;
    }



    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: whitesmoke;
        padding-left: 80px;
        position: relative;
    }

    .back_to {
        position: absolute;
        left: 0;
        top: 0;
        background: #4472c4;
        height: 55.4px;
        padding: 0 15px;
        line-height: 55px;
        color: #fff;
        text-decoration: none;
    }

    .onSensor {
        float: inline-end;
        padding-right: 11px;
        color: green;
    }

    .offSensor {
        padding-left: 12px;
        color: red;
    }

    /* table block  */
    .underline-text {
        position: relative;
    }

    .underline-text::after {
        content: "";
        position: absolute;
        width: 30px;
        height: 2px;
        background-color: #000;
        bottom: 22px;
        left: 26px;
    }

    @media (max-width: 991px) {
        .site_name {
            max-width: 100%;
            padding: 20px 0 0;
            padding-left: 66px;
            position: relative;
        }
    }
</style>
<!-- header -->
<section class="note_block">
    <div class="main-container">
        <form class="example-form" (ngSubmit)="proceedData()">
            <div class="inner-row">
                <div class="site_name">
                    <a class="back_to" routerLink="/home">Back</a>
                    <mat-form-field class="example-full-width" style="width: 306px;margin: -20px;">
                        <input type="text" placeholder="-- Select --" matInput [formControl]="mySiteName"
                            [matAutocomplete]="auto">

                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.textLabel">
                                {{ option.textLabel }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                </div>
                <div>
                    <p class="onSensor"><span style="font-weight: 500;"> Sensor ON:</span> {{ onCount }}</p>
                    <p class="offSensor"><span style="font-weight: 500;"> Sensor OFF:</span> {{ offCount }}</p>
                </div>

                <div class="sensor-table">
                    <table class="sensors-tbl">
                        <tbody>
                            <tr>
                                <th class="sensor_custom">Sensor</th>
                                <th class="sensor_custom">On/Off</th>
                            </tr>
                            <tr *ngFor="let option of sensorSiteData">
                                <td>{{ option.name }}</td>
                                <td class="status form-control" [class.undqw@erline-text]="option.optionChanged"
                                    [ngClass]="{ 
                                    'offClass': option.offCondition, 
                                    'onClass': option.onCondition,
                                    'activeOnClass': option.activeStatus === 'ON', 
                                    'activeOffClass': option.activeStatus === 'OFF'
                                }">
                                    <select (change)="onStatusChange(option, $event)" class="form-control">
                                        <option value="ON" [attr.selected]="option.activeStatus === 'ON' ? true : null">
                                            ON
                                        </option>
                                        <option value="OFF"
                                            [attr.selected]="option.activeStatus === 'OFF' ? true : null">
                                            OFF
                                        </option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="sensor-list"><span> Changes Made:</span> {{ changesCount }}</div>
                <div class="form-bt">
                    <button type="submit" class="save_btn"> Save</button>
                    <a (click)="cancel()" class="cancel_btn">Cancel</a>
                </div>

            </div>
        </form>
    </div>
</section>
<!-- Modal -->
<div class="modal fade image_grid" id="cameraModal" tabindex="-1" role="dialog" aria-labelledby="cameraModalTitle"
    aria-hidden="true" data-bs-backdrop='static'>
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade image_grid" id="cameraModal" tabindex="-1" role="dialog" aria-labelledby="cameraModalTitle"
    aria-hidden="true" data-bs-backdrop='static'>

</div>